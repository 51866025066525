import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './HeadsUpBasic.css';

function HeadsUpBasic({ language, user }) {
  const [randomWord, setRandomWord] = useState('');
  const [wordList, setWordList] = useState([]); // 단어 목록 상태 추가
  const [score, setScore] = useState(0);
  const [countdown, setCountdown] = useState(3);
  const [timer, setTimer] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [ageGroup, setAgeGroup] = useState(null);
  const [gameTime, setGameTime] = useState(60);
  const [gameOver, setGameOver] = useState(false); // New state for game over

  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';

  // Fetch random word from the database
  const fetchRandomWord = async () => {
    if (!ageGroup) return;

    let response; // response 변수를 if-else 블록 바깥에 선언
    try { 

      if (language === 'KR') {
        response = await axios.get(`${SERVER_ROOT}/api/words_kr`, {
          params: { ageGroup },
        });
      } else {
        response = await axios.get(`${SERVER_ROOT}/api/words_en`, {
          params: { ageGroup },
        });
      } 
      
      const newWord = response.data.word || 'No Data Available';
      setRandomWord(newWord);
      setWordList((prevList) => [...prevList, newWord]); // 단어를 배열에 추가

    } catch (error) {
      console.error('Error fetching random word:', error);
    }
  };

  // Play sound effect
  const playSound = (soundType) => {
    let audio;
    switch (soundType) {
      case 'start':
        audio = new Audio('/sound/gamestart.m4a'); // Replace with actual sound file
        break;
      case 'correct':
        audio = new Audio('/sound/correct.m4a'); // Replace with actual sound file
        break;
      case 'pass':
        audio = new Audio('/sound/pass.m4a'); // Replace with actual sound file
        break;
      case 'gameOver':
        audio = new Audio('path/to/game-over-sound.mp3'); // Replace with actual sound file
        break;
      default:
        return;
    }
    audio.play();
  };

  // Start game
  const startGame = () => {
    setCountdown(3);
    setIsPlaying(true);
    setTimer(gameTime);
    fetchRandomWord();
    playSound('start');

    const countdownInterval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownInterval);
          startTimer();
          setTimeout(() => {
            fetchRandomWord();
          }, 500);
        }
        return prev - 1;
      });
      fetchRandomWord();
    }, 1000);
  };

  // Start the timer when countdown finishes
  const startTimer = () => {
    const timerInterval = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);
          endGame(); // End the game when time runs out
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  // Handle correct guess
  const handleCorrect = () => {
    setScore((prev) => prev + 1);
    fetchRandomWord();
    playSound('correct');
  };

  // Handle pass
  const handlePass = () => {
    fetchRandomWord();
    playSound('pass');
  };

  // End game and show score
  const endGame = () => {
    setIsPlaying(false);
    setGameOver(true); // Set game over to true
    playSound('gameOver');
  };

  // Reset game
  const resetGame = () => {
    setRandomWord('');
    setWordList([]); // 단어 목록 초기화
    setScore(0);
    setCountdown(3);
    setTimer(0);
    setIsPlaying(false);
    setGameOver(false); // Reset game over state
    setAgeGroup(null);
  };

  // 안내글 추가 섹션
  const gameInstructions = (
    <div className="game-instructions">
      <h2>게임 안내</h2>
      <p>
        이 게임은 두 명이 함께 즐길 수 있는 재미있는 퀴즈 게임입니다. 한 명의 스마트폰 화면에는 랜덤 단어가 나타나며, 이 단어를 상대방에게 설명합니다.
        힌트를 듣는 사람은 단어를 맞추거나 패스를 선택합니다. 
        상대방이 정답을 맞추면 점수가 올라가고, 제한 시간이 끝날 때까지 더 많은 단어를 맞추는 것이 목표입니다. 
        손쉽게 참여할 수 있어 모든 연령층이 즐길 수 있는 게임입니다!
      </p>
    </div>
  );

  return (
    <div className="HeadsUp">
      {!ageGroup && !gameOver && (
        <div>
          {gameInstructions} 
        <div className="age-group-selection">
          <h2>Select Age Group</h2>
          <button onClick={() => setAgeGroup('Kids')}>Kids</button>
          <button onClick={() => setAgeGroup('Teens')}>Teens</button>
          <button onClick={() => setAgeGroup('Adults')}>Adults</button>
          <button onClick={() => setAgeGroup('All')}>All</button>
        </div>
        </div>
      )}

      {ageGroup && !isPlaying && !gameOver && (
        <div className="start-screen">
          <h2>Get Ready!</h2>
          <div>
            <label htmlFor="gameTime">Set Game Time (seconds): </label>
            <input
              type="number"
              id="gameTime"
              value={gameTime}
              onChange={(e) => setGameTime(Number(e.target.value))}
            />
          </div>
          <div>
            <button onClick={startGame}>Start Game</button>
          </div>
        </div>
      )}

      {isPlaying && (
        <div className="game-screen">
          {countdown > 0 ? (
            <h2>{countdown}</h2>
          ) : (
            <>
              <h1>{randomWord}</h1>
              <div className="game-controls">
                <button onClick={handleCorrect}>Correct</button>
                <button onClick={handlePass}>Pass</button>
              </div>
            </>
          )}
          <div className="score">Score: {score}</div>
          <div className="timer">Time Left: {timer}s</div>
        </div>
      )}

      {gameOver && (
        <div className="game-over-screen">
          <h2>Game Over</h2>
          <div className="final-score">Your Score: {score}</div>
          <button className="reset-button" onClick={resetGame}>
            Restart Game
          </button>
 
          <div className="record-list" >
            <h3>{language === 'KR' ? '퀴즈에 나온 단어들' : 'Words from the quiz '}</h3>
                <table>
                  <thead>
                    <tr>
                      <th> {language === 'KR' ? "이름" : "Name"} </th>
                      <th> {language === 'KR' ? "구글" : "Google"}  </th>
                      <th> {language === 'KR' ? "나무위키" : "Namu Wiki"} </th> 
                    </tr>
                  </thead>
                  <tbody> 
                    {wordList.map((word, index) => (
                      <tr key={index}>
                        <td> {word} </td>
                        <td> 
                          <a
                            href={`https://www.google.com/search?q=${encodeURIComponent(word)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {language === 'KR' ? "검색" : "Search"}
                          </a>
                        </td> 
                        <td> 
                          <a
                            href={`https://namu.wiki/w/${encodeURIComponent(word)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {language === 'KR' ? "보러가기" : "Go and see"}
                          </a>
                        </td> 
                      </tr>
                    ))}
                  </tbody>
                </table>
                
          </div>
        </div>
      )}
    </div>
  );
}

export default HeadsUpBasic;
