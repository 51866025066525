import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./HeadsUp.css";

function AudioVisualizer() {
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);

  useEffect(() => {
    const initAudioVisualizer = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();

        analyser.fftSize = 2048;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        audioContextRef.current = audioContext;
        analyserRef.current = analyser;
        dataArrayRef.current = dataArray;

        visualize();
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    };

    const visualize = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const analyser = analyserRef.current;
      const dataArray = dataArrayRef.current;

      const draw = () => {
        if (!analyser) return;

        analyser.getByteTimeDomainData(dataArray);

        ctx.fillStyle = "rgb(200, 200, 200)";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.lineWidth = 2;
        ctx.strokeStyle = "rgb(0, 0, 0)";
        ctx.beginPath();

        const sliceWidth = canvas.width / dataArray.length;
        let x = 0;

        for (let i = 0; i < dataArray.length; i++) {
          const v = dataArray[i] / 128.0;
          const y = (v * canvas.height) / 2;

          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        ctx.lineTo(canvas.width, canvas.height / 2);
        ctx.stroke();

        requestAnimationFrame(draw);
      };

      draw();
    };

    initAudioVisualizer();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  return <canvas ref={canvasRef} id="audio-visualizer" width="600" height="200"></canvas>;
}

function HeadsUp({ language, user }) {
  const [randomWord, setRandomWord] = useState("");
  const [score, setScore] = useState(0);
  const [countdown, setCountdown] = useState(3);
  const [timer, setTimer] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [ageGroup, setAgeGroup] = useState(null);
  const [gameTime, setGameTime] = useState(60);
  const [gameOver, setGameOver] = useState(false);
  const [recognizedText, setRecognizedText] = useState(""); // Recognized speech text


  const recognitionRef = useRef(null);
  const randomWordRef = useRef(randomWord);

  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || "http://localhost:3001";


  
  useEffect(() => {
    if ("webkitSpeechRecognition" in window || "SpeechRecognition" in window) {
      console.log("SpeechRecognition is supported");
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      if (language === 'KR') {
        recognition.lang = "ko-KR"; // 한국어로 설정
      } else {
        recognition.lang = "en-US"; // Set language
      } 
      
      recognition.continuous = true; // Enable continuous recognition
      recognition.interimResults = false; // Allow interim results
      recognition.maxAlternatives = 1; // Limit to one alternative
  
      recognition.onstart = () => console.log("Speech recognition started");
      recognition.onspeechstart = () => console.log("Speech detected");
      recognition.onspeechend = () => console.log("Speech ended");
      recognition.onend = () => console.log("Speech recognition ended");

      recognition.onaudiostart = () => console.log("Audio capturing started");
      recognition.onaudioend = () => console.log("Audio capturing ended");
      recognition.onsoundstart = () => console.log("Sound detected");
      recognition.onsoundend = () => console.log("Sound stopped");
      recognition.onnomatch = () => console.log("No matching speech recognized");

  
      // Use randomWordRef to access the latest value
      recognition.onresult = (event) => {
        const lastResult = event.results[event.results.length - 1][0].transcript.trim();
        console.log("Speech recognized:", lastResult);
        console.log("Random word:", randomWordRef.current); // Access via ref
        setRecognizedText(lastResult);
  
        if (lastResult.toLowerCase().trim() === randomWordRef.current.toLowerCase().trim()) {
          handleCorrect();
        } else if (lastResult.toLowerCase().trim() === "pass") {
          handlePass();
        }
      };
  
      recognition.onerror = (event) => {
        if (event.error === "no-speech") {
          console.warn("No speech detected. Please speak louder or check your microphone.");
          alert("No speech detected. Please try again.");
        } else {
          console.error("Speech recognition error:", event.error);
        }

      };
  
      recognitionRef.current = recognition;
    } else {
      console.error("SpeechRecognition not supported in this browser.");
    }
  }, []); // Empty dependency array ensures this effect runs only once
  
  // Update randomWordRef whenever randomWord changes
  useEffect(() => {
    randomWordRef.current = randomWord;
  }, [randomWord]);
  
  

  const fetchRandomWord = async () => {
    console.log('Fetching a new word');
    if (!ageGroup) return;

    let response; // response 변수를 if-else 블록 바깥에 선언
    try {
      if (language === 'KR') {
        response = await axios.get(`${SERVER_ROOT}/api/words_kr`, {
          params: { ageGroup },
        });
      } else {
        response = await axios.get(`${SERVER_ROOT}/api/words_en`, {
          params: { ageGroup },
        });
      }
      
      setRandomWord(response.data.word || "No Data Available");
    } catch (error) {
      console.error("Error fetching random word:", error);
    }
  };

  const playSound = (soundType) => {
    let audio;
    switch (soundType) {
      case "start":
        audio = new Audio("/sound/gamestart.m4a");
        break;
      case "correct":
        audio = new Audio("/sound/correct.m4a");
        break;
      case "pass":
        audio = new Audio("/sound/pass.m4a");
        break;
      case "gameOver":
        audio = new Audio("/sound/gameover.m4a");
        break;
      default:
        return;
    }
    audio.play();
  };

  const startGame = () => {
    setCountdown(3);
    setIsPlaying(true);
    setTimer(gameTime);
    fetchRandomWord();
    playSound("start");
  
    // Start speech recognition
    if (recognitionRef.current) {
      recognitionRef.current.start();
      console.log("Speech recognition started");
    } else {
      console.error("Speech recognition instance not initialized");
    }
  
    const countdownInterval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownInterval);
          startTimer();
        }
        return prev - 1;
      });
    }, 1000);
  };
  

  const startTimer = () => {
    const timerInterval = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);
          endGame();
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const handleCorrect = () => {
    setScore((prev) => prev + 1);
    
    playSound("correct");
    fetchRandomWord();
  };

  const handlePass = () => {
    
    playSound("pass");
    fetchRandomWord();
  };

  const endGame = () => {
    setIsPlaying(false);
    setGameOver(true);
    playSound("gameOver");
  
    // Stop speech recognition
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      console.log("Speech recognition stopped");
    }
  };
  

  const resetGame = () => {
    setRandomWord("");
    setScore(0);
    setCountdown(3);
    setTimer(0);
    setIsPlaying(false);
    setGameOver(false);
    setAgeGroup(null);
    setRecognizedText(""); // Reset recognized text
  };

  // 안내글 추가 섹션
  const gameInstructions = (
    <div className="game-instructions">
      <h2>게임 안내</h2>
      <p>
        양세찬 게임은 두 명이 함께 즐길 수 있는 재미있는 퀴즈 게임입니다. 한 명이 스마트폰을 머리 위로 들고 화면에 표시된 단어를 보지 못하게 한 상태에서,
        상대방이 단어에 대한 힌트를 설명합니다. 스마트폰 화면에는 "Quiz"라는 글자와 단어가 나타나며, 힌트를 듣는 사람은 단어를 맞추거나 패스를 선택합니다.
        정답을 맞추면 점수가 올라가고, 제한 시간이 끝날 때까지 더 많은 단어를 맞추는 것이 목표입니다. 손쉽게 참여할 수 있어 모든 연령층이 즐길 수 있는 게임입니다!
      </p>
    </div>
  );

  return (
    <div className="HeadsUp">
      {!ageGroup && !gameOver && (
        <div>
          {gameInstructions} 
        <div className="age-group-selection">
          <h2>Select Age Group</h2>
          <button onClick={() => setAgeGroup("Kids")}>Kids</button>
          <button onClick={() => setAgeGroup("Teens")}>Teens</button>
          <button onClick={() => setAgeGroup("Adults")}>Adults</button>
          <button onClick={() => setAgeGroup("All")}>All</button>
        </div>
        </div>
      )}

      {ageGroup && !isPlaying && !gameOver && (
        <div className="start-screen">
          <h2>Get Ready!</h2>
          <div>
            <label htmlFor="gameTime">Set Game Time (seconds): </label>
            <input
              type="number"
              id="gameTime"
              value={gameTime}
              onChange={(e) => setGameTime(Number(e.target.value))}
            />
          </div>
          <button onClick={startGame}>Start Game</button>
        </div>
      )}

        {isPlaying && (
        <div className="game-screen">
            <AudioVisualizer />
            {countdown > 0 ? (
            <h2>{countdown}</h2>
            ) : (
            <>
                <h1>{randomWord}</h1>
                <div className="recognized-text">
                    <p>You said:</p>
                    <p className="highlight">{recognizedText}</p>
                </div>
                <div className="game-controls">
                    <button onClick={handleCorrect}>Correct</button>
                    <button onClick={handlePass}>Pass</button>
                </div>
            </>
            )}
            <div className="score">Score: {score}</div>
            <div className="timer">Time Left: {timer}s</div>
        </div>
        )}

      {gameOver && (
        <div className="game-over-screen">
          <h2>Game Over</h2>
          <div className="final-score">Your Score: {score}</div>
          <button onClick={resetGame}>Restart Game</button>
        </div>
      )}
    </div>
  );
}

export default HeadsUp;
