// Diary.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; 
import './Diary.css';
import { BiTrash } from 'react-icons/bi';
import { BiCopy } from "react-icons/bi";
import { BiShare, BiShareAlt } from "react-icons/bi"; // Importing an icon for the share button

import SplashScreen from './SplashScreen'; // Import SplashScreen
import { useNavigate } from 'react-router-dom';


function Diary({ language, user }) {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const [webpageData, setWebpageData] = useState([]);
  const [sloganText, setSloganText] = useState('');
  const [sitelist, setSitelist] = useState([]);
  const [utilitylist, setUtilitylist] = useState([]);
  const [sharedList, setSharedList] = useState([]); // New state for shared list
  const [newSite, setNewSite] = useState({ name: '', userEmail: '', url: '' });
  const [tooltipVisible, setTooltipVisible] = useState(null);
  const [tooltipType, setTooltipType] = useState('');
  const [tooltipContent, setTooltipContent] = useState('');
  const [editingTitleId, setEditingTitleId] = useState(null);
  const [editingTitleUrl, setEditingTitleUrl] = useState('');
  const [editedTitle, setEditedTitle] = useState('');
  // const [copySuccess, setCopySuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false); 
  const [tableName, setTableName] = useState(null);
  const [siteToDelete, setSiteToDelete] = useState(null);

  const [showThumbnailPopup, setShowThumbnailPopup] = useState(false);
  const [newThumbnailUrl, setNewThumbnailUrl] = useState('');
  const [siteToEdit, setSiteToEdit] = useState(null);

  const [touchTimeoutId, setTouchTimeoutId] = useState(null);


  const [toplevelActiveTab, settoplevelActiveTab] = useState('myBookmarks'); // 현재 활성 탭을 추적 

  const [activeTab, setActiveTab] = useState('sitelist'); // 현재 활성 탭을 추적
  const [defaultSites, setDefaultSites] = useState([]); // default-sites 컨테이너에 사용할 사이트 목록

  const [showShareOverlay, setShowShareOverlay] = useState(false);
  const [invitedUserEmail, setInvitedUserEmail] = useState('');
  const [sharedSite, setSharedSite] = useState(null); // To hold the site data for sharing
  const [shareOption, setShareOption] = useState(''); // To manage checkbox options

  const [showOverlay, setShowOverlay] = useState(false);
  const [clipboardUrl, setClipboardUrl] = useState('');
 
  

  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001'; 
  
  let INPUT_UPSLIDE = 350;

  const inputRef = useRef(null); // Ref for the editable input
  const userId = user ? user.uid : 'unknown'; 

  const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isMobileDevice = isMobile();


  // default-sites 데이터 가져오기
  useEffect(() => {
    axios.get(`${SERVER_ROOT}/api/sitelist/unknown`) // user=null에 해당하는 사이트 목록 불러오기
      .then((response) => {
        setDefaultSites(response.data);
      })
      .catch((error) => console.error("Error fetching default sites:", error));
  }, []);

  
  const fetchSitelist = () => {
    axios.get(`${SERVER_ROOT}/api/sitelist/${userId}`)
      .then((response) => {
        const updatedSitelist = response.data.map(site => {
            if (!site.title || site.title.trim() === '' || site.title === 'NULL') {
                const url = new URL(site.url);
                site.title = url.hostname.replace('www.', ''); // 기본적으로 도메인 이름을 title로 설정
            } 
            return site;
        });
        setSitelist(updatedSitelist);
      })
      .catch((error) => {
        console.error("Error fetching sitelist:", error);
        setSitelist([]); // Reset sitelist to empty array on error
      });
  };

  // // Call fetchSitelist inside useEffect on component mount
  // useEffect(() => {
  //   fetchSitelist();
  // }, [userId]);
    
   // Function to fetch shared list based on the user email or "To all"
   const fetchSharedList = () => {
    // if (!user || !user.email) return;

    let userEmail = 'To all';
    if(user) { 
      userEmail = user.email;
    }

    
    axios.get(`${SERVER_ROOT}/api/sharedlist`, {
      params: { invitedUserEmail: userEmail }
    })
    .then(response => {
      setSharedList(response.data);
    })
    .catch(error => console.error("Error fetching shared list:", error));
  };

  const fetchUtilityList = () => {
    axios.get(`${SERVER_ROOT}/api/utilitylist/`)
      .then(response => { 
        setUtilitylist(response.data);
      })
      .catch((error) => {
        console.error("Error fetching fetchUtilityList:", error); 
      });
  };

  // Fetch sitelist and sharedlist on mount
  useEffect(() => {
    fetchSitelist();
    fetchSharedList();
    fetchUtilityList();
  }, [userId, user?.email]);



  // 마우스 우클릭 이벤트 핸들러
  const handleRightClickToAddSite = async (e) => { 
    e.preventDefault(); // Prevent the default context menu
    try {
      const clipboardText = await navigator.clipboard.readText();
      if (isValidUrl(clipboardText)) {
        setClipboardUrl(clipboardText); // Set the clipboard URL for display
        setShowOverlay(true); // Show the overlay
      } else {
        alert(language === 'EN' ? 'Invalid URL in clipboard!' : '클립보드에 유효한 URL이 없습니다!');
      }
    } catch (error) {
      console.error('Error reading clipboard:', error);
      alert(language === 'EN' ? 'Could not read clipboard!' : '클립보드를 읽을 수 없습니다!');
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch {
      return false;
    }
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation(); // Prevent clicks inside the overlay from closing it
  };

  const closeOverlay = () => {
    setShowOverlay(false);
    setClipboardUrl('');
  };

  const addNewSite = () => {
    if (!newSite.url) {
      alert("사이트 URL을 입력해주세요.");
      return;
    }
  
    newSite.name = user.displayName;
    newSite.userEmail = user.email;
  
    axios.post(`${SERVER_ROOT}/api/sitelist`, { userId: userId || 'unknown', ...newSite })
    .then((response) => {
      const addedSite = response.data;
      // title이 비어 있거나 의미 없는 값일 때 대체하기
      if (!addedSite.title || addedSite.title.trim() === '' || addedSite.title === 'NULL') {
        const url = new URL(addedSite.url);
        addedSite.title = url.hostname.replace('www.', ''); // 기본적으로 도메인 이름을 title로 설정
      } 
    //   console.log(`addedSite.title: ${addedSite.title}`); 

      setSitelist([...sitelist, addedSite]);
      setNewSite({ name: '', userEmail: '', url: '' });

      fetchSitelist();
      if (isMobileDevice) window.scrollTo(0, 0);
    })
    .catch((error) => console.error("Error adding new site:", error));
  };
  

  const deleteSite = (site) => {
    switch (tableName) {
      case 'sitelist':
        axios.delete(`${SERVER_ROOT}/api/sitelist`, { 
          params: { 
            id: site.id, 
            userId, 
            url: site.url 
          } 
        })
        .then(() => {
          setSitelist(sitelist.filter(item => item.id !== site.id || item.userId !== userId || item.url !== site.url));
        })
        .catch((error) => console.error("Error deleting site:", error));
        break;
      case 'sharedlist':
        axios.delete(`${SERVER_ROOT}/api/sharedlist`, { 
          params: { 
            id: site.id, 
            userId, 
            url: site.url 
          } 
        })
        .then(() => {
          setSharedList(sharedList.filter(item => item.id !== site.id || item.userId !== userId || item.url !== site.url));
        })
        .catch((error) => console.error("Error deleting site:", error));
        break;
      default:
        break;
    }
    
  }; 

  const handleMouseEnter = (type, message) => {
    
    console.log(`type: ${type}`);
    console.log(`message: ${message}`);

    setTooltipType(type);
    setTooltipContent(message);
    setTooltipVisible(true); 
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleLongPress = (url) => {
    if (isMobileDevice) {
      setTooltipType('url');
      setTooltipContent(url);
      setTooltipVisible(true);
    }
  };

  const handleTooltipClick = () => {
    if (isMobileDevice) setTooltipVisible(false);
  };

  const getValueByName = (dataArray, name) => {
    const record = dataArray.find(record => record.name === name);
    return record ? record.value : null;
  };

  const handleTitleDoubleClick = (id, url, title) => {
    setEditingTitleId(id);
    setEditingTitleUrl(url);
    setEditedTitle(title);
  };

  const handleTitleChange = (e) => setEditedTitle(e.target.value);

  const saveTitle = (id, url) => {
    setSitelist(sitelist.map(site => 
      site.url === url ? { ...site, title: editedTitle } : site
    ));
    axios.put(`${SERVER_ROOT}/api/sitelist/title`, { title: editedTitle, url }) // URL도 함께 전달
      .then(() => {
        setEditingTitleId(null); 
        setEditingTitleUrl(''); 
      })
      .catch((error) => console.error("Error updating title:", error));
  };


  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      saveTitle(editingTitleId);
      setEditingTitleId(null);
      setEditingTitleUrl('');
    }
  };

  useEffect(() => {
    if (editingTitleId !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [editingTitleId]);

  useEffect(() => {
    const tableName = language === 'EN' ? 'webpage_EN' : 'webpage';
    axios.get(`${SERVER_ROOT}/api/${tableName}`)
      .then((response) => {
        setWebpageData(response.data);
        const sloganRecord = response.data.find(item => item.name === 'App.js_slogun');
        if (sloganRecord) setSloganText(sloganRecord.value);
      })
      .catch((error) => console.error("Error fetching webpage data:", error));
  }, [language]);
  
  function getDomain(url) {
    try {
      const siteUrl = new URL(url);
      const domain = `${siteUrl.hostname}`;
      return domain;
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  }
   
  // const copyToClipboard = async (url) => {
  //   try {
  //     if (navigator.clipboard && navigator.clipboard.writeText) {
  //       // 최신 브라우저 및 HTTPS 환경에서는 navigator.clipboard 사용
  //       await navigator.clipboard.writeText(url);
  //       setCopySuccess(true);
  //     } else {
  //       // 구형 모바일 브라우저 지원을 위한 fallback
  //       const textArea = document.createElement("textarea");
  //       textArea.value = url;
  //       // textArea.style.position = "fixed"; // 요소가 눈에 보이지 않도록 고정
  //       textArea.style.opacity = "0";      // 투명하게 설정
  //       document.body.appendChild(textArea);
  //       textArea.select();
  //       document.execCommand("copy");
  
  //       setCopySuccess(true);

  //       // 짧은 지연 후 요소 제거
  //       document.body.removeChild(textArea);
  
        
  //     }
  
  //     setTimeout(() => setCopySuccess(false), 2000); // 2초 후에 메시지 숨기기
  //   } catch (err) {
  //     console.error("Failed to copy: ", err);
  //   }
  // };
  
  

  // Function to handle delete button click
  const handleDeleteClick = (site) => {
    setSiteToDelete(site);
    setShowConfirm(true); // Show the confirmation popup
  };

  // Function to confirm deletion
  const confirmDelete = () => {
    if (siteToDelete) {
      deleteSite(siteToDelete); // Call the delete function with the site URL
      setShowConfirm(false); // Close the popup
      setSiteToDelete(null); // Reset the state
    }
  };

  // Function to cancel deletion
  const cancelDelete = () => {
    setShowConfirm(false); // Close the popup
    setSiteToDelete(null); // Reset the state
  };

  // Function to handle opening the popup
  const openThumbnailPopup = (site, event) => {
    event.preventDefault(); // Right-click 메뉴 방지
    setSiteToEdit(site);
    setNewThumbnailUrl('');
    setShowThumbnailPopup(true);
  };

  // Function to update the thumbnail URL in the database
  const updateThumbnail = async () => {
    if (!siteToEdit || !newThumbnailUrl) return;
  
    try {
      axios.put(`${SERVER_ROOT}/api/sitelist/thumbnail`, {
        id: siteToEdit.id,
        url: siteToEdit.url,
        thumbnail: newThumbnailUrl
      });
      setSitelist(prevSites =>
        prevSites.map(site =>
          site.id === siteToEdit.id && site.url === siteToEdit.url
            ? { ...site, thumbnail: newThumbnailUrl }
            : site
        )
      );
      setShowThumbnailPopup(false);
      setSiteToEdit(null);
    } catch (error) {
      console.error('Error updating thumbnail:', error);
    }
  };


  const [showSplash, setShowSplash] = useState(true); // State to control splash screen
  const [imagesLoaded, setImagesLoaded] = useState(false); // Tracks image preload status

  // Use a timeout to ensure the splash screen stays for the full 6 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 4000);  

    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, []);
  
  if (showSplash) return <SplashScreen />; // Show the splash screen initially


  // Share Site function
  

  // Function to open the overlay and store the site to share
  const handleShareButtonClick = (site) => {
    setSharedSite(site);
    setShowShareOverlay(true);
  };

  // Function to handle the share submission
  const handleShareSubmit = () => {
    if (!user || !sharedSite) return;

    const data = {
      id: sharedSite.id,
      userId: user.uid,
      name: sharedSite.name,
      userEmail: user.email,
      invitedUserEmail: invitedUserEmail || shareOption,
      url: sharedSite.url,
      title: sharedSite.title,
      description: sharedSite.description,
      favicon: sharedSite.favicon,
      thumbnail: sharedSite.thumbnail,
      thumbnail_local: sharedSite.thumbnail_local,
    };

    axios.post(`${SERVER_ROOT}/api/sharedlist`, data)
      .then(() => {
        setShowShareOverlay(false);
        setInvitedUserEmail(''); // Reset the input
        setShareOption(''); // Reset the option
        alert('Site shared successfully!');
      })
      .catch(error => console.error("Error sharing site:", error));
  };


  const handleSiteURLWindow = (url) => {
    try {
      const baseDomain = new URL(url).hostname;

      // 내부 라우트인 경우 처리
      if (baseDomain === "www.doldari.com") {
        const routePath = new URL(url).pathname; // "/intro" 같은 경로 추출
        navigate(routePath); // React Router의 navigate 사용
      } else {
        // 외부 URL의 경우 새 탭에서 열기
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Invalid URL:", error);
    }
  };

  return (
    <div className="Diary">
      {/* <div> */}
        <header className="Diary-header">
        </header>

        <main>
        <div className='myBookmarkContainer'
        onClick={(e) => {
          // 클릭한 요소가 mySites의 자식 요소가 아니라면 스크롤 실행
          if (e.target === e.currentTarget) {
            window.scrollTo(0, 0);
          }
        }}>  
          <div className='myBookmarkTitle'> 
              <div className="Greetings">
                <img 
                  src={language === 'KR' ? '/images/myDiary.png' : '/images/myDiary.png'} 
                  alt={language === 'KR' ? '나의 일기장' : 'My Daily Note'} 
                  style={{ width: 'auto', height: '50px' }} // Adjust sizes as needed
                  onMouseEnter={() => handleMouseEnter('message',
                    language === 'KR' ? '하루의 기록을 간단하게.' : 'Write your day simply.' )}
                  onMouseOut={handleMouseLeave}
                /> 
              </div>
              {tooltipVisible && tooltipType === 'message' && (
                <div className="tooltip" onClick={handleTooltipClick}>
                  {tooltipContent} 
                </div>
              )}
              <div className="subtitles">
                {user? (
                  <div className="tab-buttons">
                    <button 
                      onClick={() => setActiveTab('defaultSites')}  
                      // className='defaultSites-button'
                      className={activeTab === 'defaultSites' ? 'active' : 'inactive'} 
                      onMouseEnter={() => handleMouseEnter('message',
                        language === 'KR' ? '이 버튼을 누르면 유명한 사이트 모음이 나옵니다.' : "Click the button and see the well-known sites." )}
                      onMouseOut={handleMouseLeave}
                    >
                      {language === 'EN' ? "Card Diary" : '카드 일기'} 
                    </button>
                    
                      
                    <button 
                      onClick={() => setActiveTab('sitelist')} 
                      // className='sitelist'
                      className={activeTab === 'sitelist' ? 'active' : 'inactive'} 
                      onMouseEnter={() => handleMouseEnter('message',
                        language === 'KR' ? '기억하고픈 링크를 여기에 붙여넣기 하세요.' : "Add new links and share to other people." )}
                      onMouseOut={handleMouseLeave}
                      onContextMenu={handleRightClickToAddSite} // Right-click to add URL
                    >
                      {language === 'EN' ? 'Picture diary' : '사진 일기'}
                    </button> 
                    
                    <button 
                      onClick={() => {
                        setActiveTab('sharedlist');
                        fetchSharedList(); // Call the function to fetch shared list data
                      }} 
                      // className='sharedlist'
                      className={activeTab === 'sharedlist' ? 'active' : 'inactive'}
                      onMouseEnter={() => handleMouseEnter('message',"사이트 클릭시 Ecash 증정.(예정)")}
                      onMouseOut={handleMouseLeave}
                    >
                      {language === 'EN' ? 'Study Note' : '연구 노드'}
                    </button> 

                    <button 
                      onClick={() => {
                        setActiveTab('utilitylist');
                        fetchUtilityList(); // Call the function to fetch shared list data
                      }} 
                      // className='utilitylist'
                      className={activeTab === 'utilitylist' ? 'active' : 'inactive'}
                      onMouseEnter={() => handleMouseEnter('message',"To Appear")}
                      onMouseOut={handleMouseLeave}
                    >
                      {language === 'EN' ? 'Voice diary' : '말로 쓰는 일기'}
                    </button> 

                  </div> 
                  
                ) : (
                    <div className="tab-buttons">
                    <button 
                      onClick={() => setActiveTab('defaultSites')}  
                      // className='defaultSites-button'
                      className={activeTab === 'defaultSites' ? 'active' : 'inactive'} 
                      onMouseEnter={() => handleMouseEnter('message',
                        language === 'KR' ? '이 버튼을 누르면 유명한 사이트 모음이 나옵니다.' : "Click the button and see the well-known sites." )}
                      onMouseOut={handleMouseLeave}
                    >
                      {language === 'EN' ? "Card Diary" : '카드 일기'} 
                    </button>
                    
                      
                    <button 
                      onClick={() => setActiveTab('sitelist')} 
                      // className='sitelist'
                      className={activeTab === 'sitelist' ? 'active' : 'inactive'} 
                      onMouseEnter={() => handleMouseEnter('message',
                        language === 'KR' ? '기억하고픈 링크를 여기에 붙여넣기 하세요.' : "Add new links and share to other people." )}
                      onMouseOut={handleMouseLeave}
                      onContextMenu={handleRightClickToAddSite} // Right-click to add URL
                    >
                      {language === 'EN' ? 'Picture diary' : '사진 일기'}
                    </button> 
                    
                    <button 
                      onClick={() => {
                        setActiveTab('sharedlist');
                        fetchSharedList(); // Call the function to fetch shared list data
                      }} 
                      // className='sharedlist'
                      className={activeTab === 'sharedlist' ? 'active' : 'inactive'}
                      onMouseEnter={() => handleMouseEnter('message',"사이트 클릭시 Ecash 증정.(예정)")}
                      onMouseOut={handleMouseLeave}
                    >
                      {language === 'EN' ? 'Study Note' : '연구 노드'}
                    </button> 

                    <button 
                      onClick={() => {
                        setActiveTab('utilitylist');
                        fetchUtilityList(); // Call the function to fetch shared list data
                      }} 
                      // className='utilitylist'
                      className={activeTab === 'utilitylist' ? 'active' : 'inactive'}
                      onMouseEnter={() => handleMouseEnter('message',"To Appear")}
                      onMouseOut={handleMouseLeave}
                    >
                      {language === 'EN' ? 'Voice diary' : '말로 쓰는 일기'}
                    </button> 

                  </div> 
                )
                } 
              </div>
          </div>
          <div className='myBookmarks'>
            
            

            {activeTab === 'sitelist' && (
              <div className="mySites" >
              {sitelist.length > 0 ? (
                sitelist.map((site, index) => (
                    <div key={index} className="site-card" style={{ position: 'relative' }}>
                        {site.thumbnail === '' ? (
                            <div className="text-thumbnail">
                            {getDomain(site.url)}
                            </div>
                        ) : (
                            <img
                              src={site.thumbnail_local ? site.thumbnail_local : site.thumbnail}
                              onClick={() => {
                                handleSiteURLWindow(site.url);                            
                              }}
                              alt={`${site.name} thumbnail`}
                              className="site-thumbnail"
                              onContextMenu={(event) => {
                                if (user && site.url !== 'unknown' && !isMobileDevice) {
                                  openThumbnailPopup(site, event);
                                }
                              }}
                              onTouchStart={(event) => {
                                if (user && site.url !== 'unknown') {
                                  event.preventDefault();
                                  // 500ms 후에 팝업이 열리도록 타이머 설정
                                  const touchTimeout = setTimeout(() => openThumbnailPopup(site, event), 500);
                                  // timeout id를 state에 저장
                                  setTouchTimeoutId(touchTimeout);
                                }
                              }}
                              onTouchEnd={() => {
                                // 터치가 끝나면 타이머를 취소
                                clearTimeout(touchTimeoutId);
                              }}
                            />
                        )}
                        <div
                            className="site-title"
                            onDoubleClick={() => {
                                setEditingTitleId(site.id);
                                setEditingTitleUrl(site.url);
                                console.log(`site.id and site.url: ${site.id}, ${site.url}.`);
                            }
                            }
                        >
                            {editingTitleUrl === site.url ? (
                                <textarea
                                rows="4" // 4줄로 제한
                                className="editingTitleInput"
                                type="text"
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                                onBlur={() => {
                                    saveTitle(site.id, site.url);
                                    setEditingTitleId(null); // Exit editing mode after saving
                                    setEditingTitleUrl(null);
                                    if (isMobileDevice) {
                                        setTimeout(() => {
                                            window.scrollTo(0, 0);
                                          }, 100); // 약간의 지연을 주어 키보드가 완전히 닫히도록 합니다.
                                      }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        saveTitle(site.id, site.url);
                                        setEditingTitleId(null); // Exit editing mode after saving
                                        if (isMobileDevice) {
                                            setTimeout(() => {
                                                window.scrollTo(0, 0);
                                              }, 100); // 약간의 지연을 주어 키보드가 완전히 닫히도록 합니다.
                                        }
                                    }
                                }}
                                autoFocus
                                />
                            ) : (
                                <div 
                                    className="site-title" 
                                    onDoubleClick={() => handleTitleDoubleClick(site.id, site.url, site.title)}
                                    onMouseEnter={() => handleMouseEnter('url',site.url)}
                                    onMouseLeave={handleMouseLeave}
                                    onTouchStart={() => handleLongPress(site.url)}
                                >
                                    {site.title}
                                </div>
                            )}
                        </div>

                        {showThumbnailPopup && (
                          <div className="popup-overlay" onClick={() => setShowThumbnailPopup(false)}>
                            <div className="popup" onClick={(e) => e.stopPropagation()}>
                              <h4>{language === 'EN' ? 'Edit Thumbnail URL' : '썸네일 URL 수정'}</h4>
                              <input
                                type="text"
                                value={newThumbnailUrl}
                                onChange={(e) => setNewThumbnailUrl(e.target.value)}
                                placeholder="Enter new thumbnail URL"
                              />
                              <button onClick={updateThumbnail}>
                                {language === 'EN' ? 'Save' : '저장'}
                              </button>
                              <button onClick={() => setShowThumbnailPopup(false)}>
                                {language === 'EN' ? 'Cancel' : '취소'}
                              </button>
                            </div>
                          </div>
                        )}
                        
                        {tooltipVisible && tooltipContent === site.url && (
                          <div className="tooltip" onClick={handleTooltipClick}>
                              {site.url.length > 30 ? `${site.url.slice(0, 27)}...` : site.url}
                          </div>
                        )}

                        
                        <div className='cardAction'>
                          {user && (
                            <>
                              <button className="delete-button" onClick={() => {
                                setTableName('sitelist');
                                handleDeleteClick(site);
                                }}>
                                <BiTrash />
                              </button>
                              <button className="share-site" onClick={() => handleShareButtonClick(site)}>
                                <BiShare />
                              </button>
                            </>
                          )}
                          {/* 삭제 확인 팝업 */}
                          {showConfirm && (
                            <div className="popup-overlay" onClick={cancelDelete}>
                              <div className="popup" onClick={(e) => e.stopPropagation()}>
                                <p>{language === 'EN' ? 'Want to delete ?' : '정말 지울까요?'}</p>
                                <button onClick={confirmDelete} className="confirm-button">
                                  {language === 'EN' ? 'Confirm' : '확인'}
                                </button>
                                <button onClick={cancelDelete} className="cancel-button">
                                  {language === 'EN' ? 'Cancel' : '취소'}
                                </button>
                              </div>
                            </div>
                          )}

                          {/* Share Overlay */}
                          {showShareOverlay && (
                            <div className="overlay" onClick={() => setShowShareOverlay(false)}> {/* Close overlay when clicking outside */}
                              <div 
                                className="overlay-content" 
                                onClick={(e) => e.stopPropagation()} /* Prevent click from closing when inside content */
                              >
                                <h3>{language === 'EN' ? 'Share this site' : '이 사이트 공유하기'}</h3>
                                
                                <input
                                  type="email"
                                  placeholder="Enter invited user email"
                                  value={invitedUserEmail}
                                  onChange={(e) => setInvitedUserEmail(e.target.value)}
                                  onKeyDown={(e) => e.key === 'Enter' && handleShareSubmit()}
                                />
                                
                                <div className="share-options">
                                  <label>
                                    <input
                                      type="checkbox"
                                      value="To all"
                                      checked={shareOption === 'To all'}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setShareOption('To all');
                                          setInvitedUserEmail(''); // Clear any manually entered email if 'To all' is selected
                                        } else {
                                          setShareOption('');
                                        }
                                      }}
                                    />
                                    To all
                                  </label>
                                  <label>
                                    <input
                                      type="checkbox"
                                      value="To 100 users"
                                      checked={shareOption === 'To 100 users'}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setShareOption('To 100 users');
                                          setInvitedUserEmail('');
                                        } else {
                                          setShareOption('');
                                        }
                                      }}
                                    />
                                    To 100 users
                                  </label>
                                </div>
                                
                                <button onClick={handleShareSubmit}>
                                  {language === 'EN' ? 'Submit' : '확인'}
                                </button>
                              </div>
                            </div>
                          )}



                          {/* <button className="copy-button" onClick={() => {
                              copyToClipboard(site.url);
                              if (isMobileDevice) {
                                  setTimeout(() => {
                                      window.scrollTo(0, 0);
                                    }, 100); 
                              }
                            }}
                            >
                            <BiCopy />
                          </button> */}

                          <button
                            className="share-button"
                            onClick={() => {
                              if (navigator.share) {
                                navigator.share({
                                  title: site.title || "Check out this site",
                                  text: '',
                                  url: site.url
                                })
                                .then(() => console.log('Successfully shared'))
                                .catch((error) => console.error('Error sharing:', error));
                              } else {
                                alert("Sharing is not supported on this device.");
                              }
                            }}
                          >
                            <BiShareAlt /> {/* Share icon */}
                          </button>
                        </div>
                        
                    </div>
                )) 
              ) : (
                <p className="no-sites-message"> {getValueByName(webpageData, 'Diary.no_registered_url')}</p>
              )}

              
              </div>  
            )}

            {/* {tooltipVisible && tooltipType === 'message' && (
              <div className="tooltip" onClick={handleTooltipClick}>
                {tooltipContent} 
              </div>
            )} */}

            {activeTab === 'sitelist' && user && (
              <div className="add-site-form">
                <input
                  type="text"
                  placeholder={
                    user
                      ? language === 'EN'
                        ? "Enter a URL (e.g., www.google.com)"
                        : "URL을 입력하세요. (예, www.google.com)"
                      : language === 'EN'
                      ? "Please login to add your sites."
                      : "로그인을 먼저 하세요."
                  } 
                    
                  value={newSite.url}
                  style={{
                    width: isMobileDevice ? '60vw' : '30vw'
                  }}
                  onLoad={() => {
                    if (isMobileDevice) {
                        setTimeout(() => {
                            window.scrollTo(0, INPUT_UPSLIDE);
                          }, 100);  
                    }
                    setNewSite({
                      url: language === 'EN' 
                        ? "Enter a new URL." 
                        : "새 사이트를 입력해주세요."
                    });
                  }}
                  onFocus={() => {
                    if (isMobileDevice) {
                        setTimeout(() => {
                            window.scrollTo(0, INPUT_UPSLIDE);
                          }, 100);  
                    }
                    setNewSite({
                      url: ""
                    });
                  }}
                  onChange={(e) => { 
                    if (user) {
                        setNewSite({ url: e.target.value });
                    } else {
                        setNewSite({
                            url: language === 'EN' 
                              ? "Login first, please." 
                              : "로그인을 하신 후에 추가하실 수 있어요."
                        });
                    }
                  }}
                  onBlur={() => {
                    if (isMobileDevice) {
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                          }, 100); // 약간의 지연을 주어 키보드가 완전히 닫히도록 합니다.
                      }
                  }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (isMobileDevice) {
                                setTimeout(() => {
                                    window.scrollTo(0, 0);
                                }, 100); // 약간의 지연을 주어 키보드가 완전히 닫히도록 합니다.
                            }
                            if (user) {
                                addNewSite();
                            } else {
                                setNewSite({ url: "Login first, please." }); 
                            }
                            
                        }
                  }}
                />
                
                <button className="add-site-confirm-button" onClick={addNewSite}> 
                    {language === 'EN' ? 'Confirm' : '새 사이트 추가'}
                </button> 
                
                

              </div>
            )}

            {activeTab === 'defaultSites' && (
              <div className="default-sites">
                {defaultSites.length > 0 ? (
                  defaultSites.map((site, index) => (
                    <div key={index} className="site-card" style={{ position: 'relative' }}>
                      {site.thumbnail === '' ? (
                        <div className="text-thumbnail">{getDomain(site.url)}</div>
                      ) : (
                        <img
                          src={site.thumbnail_local ? site.thumbnail_local : site.thumbnail}
                          onClick={() => window.open(site.url, '_blank')}
                          alt={`${site.name} thumbnail`}
                          className="site-thumbnail"
                        />
                      )}
                       
                      <div 
                          className="site-title"  
                          onMouseEnter={() => handleMouseEnter('url',site.url)}
                          onMouseLeave={handleMouseLeave}
                          onTouchStart={() => handleLongPress(site.url)}
                      >
                          {site.title}
                      </div>

                      {tooltipVisible && tooltipContent === site.url && (
                        <div className="tooltip" onClick={handleTooltipClick}>
                            {site.url}
                        </div>
                      )}
                      
                      <div className='cardAction'>
                          {/* <button className="copy-button" onClick={() => {
                              copyToClipboard(site.url);
                              if (isMobileDevice) {
                                  setTimeout(() => {
                                      window.scrollTo(0, 0);
                                    }, 100); 
                              }
                            }}
                            >
                            <BiCopy />
                          </button> */}
                          
                          <button
                            className="share-button"
                            onClick={() => {
                              if (navigator.share) {
                                navigator.share({
                                  title: site.title || "Check out this site",
                                  text: '',
                                  url: site.url
                                })
                                .then(() => console.log('Successfully shared'))
                                .catch((error) => console.error('Error sharing:', error));
                              } else {
                                alert("Sharing is not supported on this device.");
                              }
                            }}
                          >
                            <BiShareAlt /> {/* Share icon */}
                          </button>
                      </div>
                    </div>

                    
                  ))
                ) : (
                  <p className="no-sites-message">
                    {language === 'EN' ? 'No default sites available' : '등록된 기본 사이트가 없습니다'}
                  </p>
                )}
              </div>
            )}

            {/* Sharedlist Tab */}
            {activeTab === 'sharedlist' && (
              <div className="sharedSites" >
                {sharedList.length > 0 ? (
                  sharedList.map((site, index) => (
                    <div key={index} className="site-card" style={{ position: 'relative' }}>
                        {site.thumbnail === '' ? (
                            <div className="text-thumbnail">
                            {getDomain(site.url)}
                            </div>
                        ) : (
                            <img
                              src={site.thumbnail_local ? site.thumbnail_local : site.thumbnail}
                              onClick={() => window.open(site.url, '_blank')}
                              alt={`${site.name} thumbnail`}
                              className="site-thumbnail"
                              onContextMenu={(event) => {
                                if (user && site.url !== 'unknown' && !isMobileDevice) {
                                  openThumbnailPopup(site, event);
                                }
                              }}
                              onTouchStart={(event) => {
                                if (user && site.url !== 'unknown') {
                                  event.preventDefault();
                                  // 500ms 후에 팝업이 열리도록 타이머 설정
                                  const touchTimeout = setTimeout(() => openThumbnailPopup(site, event), 500);
                                  // timeout id를 state에 저장
                                  setTouchTimeoutId(touchTimeout);
                                }
                              }}
                              onTouchEnd={() => {
                                // 터치가 끝나면 타이머를 취소
                                clearTimeout(touchTimeoutId);
                              }}
                            />
                        )} 
                             
                        <div 
                            className="site-title"  
                            onMouseEnter={() => handleMouseEnter('url',site.url)}
                            onMouseLeave={handleMouseLeave}
                            onTouchStart={() => handleLongPress(site.url)}
                        >
                            {site.title}
                        </div>

                       
                        {tooltipVisible && tooltipContent === site.url && (
                          <div className="tooltip" onClick={handleTooltipClick}>
                              {site.url.length > 30 ? `${site.url.slice(0, 27)}...` : site.url}
                          </div>
                        )}

                        <div className='cardAction'> 
                          

                          {user && user.email === site.userEmail && ( 
                                <button className="delete-button" onClick={() => {
                                  setTableName('sharedlist');
                                  handleDeleteClick(site);
                                  }}>
                                  <BiTrash />
                                </button>
                          )
                          }
                          {user && (
                            <> 
                              <button
                                className="share-button"
                                onClick={() => {
                                  if (navigator.share) {
                                    navigator.share({
                                      title: site.title || "Check out this site",
                                      text: '',
                                      url: site.url
                                    })
                                    .then(() => console.log('Successfully shared'))
                                    .catch((error) => console.error('Error sharing:', error));
                                  } else {
                                    alert("Sharing is not supported on this device.");
                                  }
                                }}
                              >
                                <BiShareAlt /> {/* Share icon */}
                              </button>
                            </>
                          )}
                          {/* 삭제 확인 팝업 */}
                          {showConfirm && (
                            <div className="popup-overlay" onClick={cancelDelete}>
                              <div className="popup" onClick={(e) => e.stopPropagation()}>
                                <p>{language === 'EN' ? 'Want to delete ?' : '정말 지울까요?'}</p>
                                <button onClick={confirmDelete} className="confirm-button">
                                  {language === 'EN' ? 'Confirm' : '확인'}
                                </button>
                                <button onClick={cancelDelete} className="cancel-button">
                                  {language === 'EN' ? 'Cancel' : '취소'}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        
                    </div>
                  ))
                ) : (
                  <p className="no-sites-message">{language === 'EN' ? 'No shared sites available' : '등록된 공유 사이트가 없습니다'}</p>
                )}
              </div>
            )}

            {/* utility Tab */}
            {activeTab === 'utilitylist' && (
              <div className="utilitylist" >
                {utilitylist.length > 0 ? (
                  utilitylist.map((site, index) => (
                    <div key={index} className="site-card" style={{ position: 'relative' }}>
                        {site.thumbnail === '' ? (
                            <div className="text-thumbnail">
                            {getDomain(site.url)}
                            </div>
                        ) : (
                            <img
                              src={site.thumbnail_local ? site.thumbnail_local : site.thumbnail}
                              onClick={() => window.open(site.url, '_blank')}
                              alt={`${site.name} thumbnail`}
                              className="site-thumbnail"
                              onContextMenu={(event) => {
                                if (user && site.url !== 'unknown' && !isMobileDevice) {
                                  openThumbnailPopup(site, event);
                                }
                              }}
                              onTouchStart={(event) => {
                                if (user && site.url !== 'unknown') {
                                  event.preventDefault();
                                  // 500ms 후에 팝업이 열리도록 타이머 설정
                                  const touchTimeout = setTimeout(() => openThumbnailPopup(site, event), 500);
                                  // timeout id를 state에 저장
                                  setTouchTimeoutId(touchTimeout);
                                }
                              }}
                              onTouchEnd={() => {
                                // 터치가 끝나면 타이머를 취소
                                clearTimeout(touchTimeoutId);
                              }}
                            />
                        )} 
                            
                        <div 
                            className="site-title"  
                            onMouseEnter={() => handleMouseEnter('url',site.url)}
                            onMouseLeave={handleMouseLeave}
                            onTouchStart={() => handleLongPress(site.url)}
                        >
                            {site.title}
                        </div>

                       
                        {tooltipVisible && tooltipContent === site.url && (
                          <div className="tooltip" onClick={handleTooltipClick}>
                              {site.url.length > 30 ? `${site.url.slice(0, 27)}...` : site.url}
                          </div>
                        )}

                        <div className='cardAction'> 
                          <button
                            className="share-button"
                            onClick={() => {
                              if (navigator.share) {
                                navigator.share({
                                  title: site.title || "Check out this site",
                                  text: '',
                                  url: site.url
                                })
                                .then(() => console.log('Successfully shared'))
                                .catch((error) => console.error('Error sharing:', error));
                              } else {
                                alert("Sharing is not supported on this device.");
                              }
                            }}
                          >
                            <BiShareAlt /> {/* Share icon */}
                          </button>
                        </div>
                        
                    </div>
                  ))
                ) : (
                  <p className="no-sites-message">{language === 'EN' ? 'No shared sites available' : '등록된 공유 사이트가 없습니다'}</p>
                )}
              </div>
            )}

            
            {/* {!user ? ( 
                <div className='message_to_new_visitors'>
                  {language === 'EN'
                    ? "You'll have a convenient web bookmark just for you."
                    : "이제부터 나만을 위한 편리한 웹 콘텐츠 북마크를 갖게 되실 것입니다."
                  }
                </div>
            ) : ( 
                <div className='message_to_new_visitors'>
                  {language === 'EN'
                    ? "If you'd like to change the title text, double click on it."
                    : "제목을 두번 클릭하시면, 수정하실 수 있습니다."
                  }
                </div> 
            )} */}
          </div> 

        
        </div>  

        {/* <div className='marketNewsContainer'>  
          <div className='marketNewsTitle'>
                <button className="Greetings">
                  {language === 'EN'
                    ? "Market News"
                    : "마켓 뉴스"
                  }
                </button>
          </div>  */}
          {/* <div className='marketNews'>
            Market news - under construction...
          </div> 

          <div className='myToDoList'>
            To Do List is under construction
          </div>  
          
        </div> */}

        </main>


        <footer>
          <div className="TGabyss">
                <Link to="/intro" style={{ color: 'white', backgroundColor: '#269830', textDecoration: 'none' }}> TG ABYSS Corp. </Link> &copy; All Rights Reserved. &nbsp;  
                <text style={{ color: 'white', backgroundColor: '#269830', textDecoration: 'none' }}>
                {/* {getValueByName(webpageData, 'App.js_ceoText') || "대표"}: &nbsp; */}
                {/* {getValueByName(webpageData, 'App.js_ceoName') || "길계태"}, &nbsp; */}
                {getValueByName(webpageData, 'App.js_ceoEmail') || "gategil1149@gmail.com"}
                 {/* | &nbsp;  */}
                {/* {getValueByName(webpageData, 'App.js_phoneText') || "대표번호"}: &nbsp; */}
                {/* {getValueByName(webpageData, 'App.js_phoneNumber') || "010-7235-5108"} &nbsp;  */}
                {/* {getValueByName(webpageData, 'App.js_companyAddressText') || "주소"}: &nbsp; */}
                {/* {getValueByName(webpageData, 'App.js_companyAddress') || "대전시 유성구 관들4길 3 (관평동)"}   */}
                </text>
                
          </div>   
        </footer>
      {/* </div> */}
    </div>
  );
}

export default Diary;
