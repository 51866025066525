import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'; // react-markdown 추가
import { signInWithPopup, signInWithRedirect } from 'firebase/auth';
import { auth, googleProvider} from './firebase';
import './Intro.css'; 

function Intro({language}) {
  const [webpageData, setWebpageData] = useState([]);
  const [sloganText, setSloganText] = useState(''); // Slogan text 상태 추가 

  const [menuItems, setMenuItems] = useState([]); 
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [viewFile, setViewFile] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false); // Animation 상태 관리
  const [isHovered, setIsHovered] = useState(false); // 마우스 오버 상태 관리
  const [isTouching, setIsTouching] = useState(false); // 터치 상태 관리
  const [isAnimating, setIsAnimating] = useState(false); // Animation 상태 관리
  const [user, setUser] = useState(null); // 로그인된 사용자 상태 추가 
//   const [language, setLanguage] = useState('KR'); // 초기 언어 설정


  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001'; 

  // 모바일 감지 함수
  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  const isMobileDevice = isMobile(); // true 혹은 false 반환

  // 데이터 가져오기: language에 따라 menulist 또는 menulist_EN 테이블 사용
  useEffect(() => {
    const tableName = language === 'EN' ? 'menulist_EN' : 'menulist';

    console.log(tableName);

    axios.get(`${SERVER_ROOT}/api/${tableName}`)
      .then((response) => {
        console.log(`Fetched ${tableName} data:`, response.data);
        setMenuItems(response.data);
      })
      .catch((error) => {
        console.error(`Error fetching ${tableName} data:`, error);
      });
  }, [language]);

  const getValueByName = (dataArray, name) => {
    const record = dataArray.find(record => record.name === name);
    return record ? record.value : null; // name이 없을 경우 null 반환
  };
  
  useEffect(() => { 
    const tableName = language === 'EN' ? 'webpage_EN' : 'webpage';

    console.log(tableName);

    axios.get(`${SERVER_ROOT}/api/${tableName}`) 
      .then(response => {
        console.log(`Fetched ${tableName} data:`, response.data); // 데이터 출력
        setWebpageData(response.data);

        // name이 'App.js_slogun'인 record 찾기
        const sloganRecord = response.data.find(item => item.name === 'App.js_slogun');
        if (sloganRecord) {
          setSloganText(sloganRecord.value); // 상태 업데이트
        } 
      })
      .catch(error => {
        console.error("Error fetching webpage data:", error);
      });
  }, [language]);

  const buf = getValueByName(webpageData, 'App.js_menuChangeInterval');
  const menuChangeInterval = parseInt(buf, 10) || 5000; 

  useEffect(() => {
    // // 메뉴 항목을 MariaDB에서 가져옵니다
    // axios.get('${SERVER_ROOT}/api/menulist') // 서버에서 menulist 정보를 가져오는 API 엔드포인트
    //   .then(response => {
    //     console.log('Fetched menulist:', response.data); // 데이터 출력
    //     setMenuItems(response.data);
    //   })
    //   .catch(error => {
    //     console.error("Error fetching menu items:", error);
    //   });

    // 5초마다 자동으로 메뉴 항목 변경 

    const interval = setInterval(() => {
      if (!isHovered && !isTouching) {
        setCurrentIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % menuItems.length;
          setSelectedItem(menuItems[newIndex]); // 새로운 인덱스에 맞게 selectedItem 업데이트
          
          // Trigger animation
          setIsAnimating(true);
          setTimeout(() => setIsAnimating(false), menuChangeInterval); // Remove animation class after 5 seconds

          return newIndex; // currentIndex 업데이트
        });
      }
    }, menuChangeInterval);

    return () => clearInterval(interval);
  }, [menuChangeInterval, menuItems.length, isHovered, isTouching]); // isHovered와 isTouching에 따라 재렌더링

  

  const handleSwipe = (direction) => {
    if (direction === 'up') {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % menuItems.length);
    } else if (direction === 'down') {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + menuItems.length) % menuItems.length);
    }
  };

  const handleItemClick = (item) => {
    window.location.href = item.url; // URL의 .html 파일을 현재 창에서 엽니다
  };

  const handleAnimationEnd = () => {
    setAnimationFinished(true); // 애니메이션이 끝나면 상태 업데이트
  };

  const isVideoFile = (url) => {
    return url && url.endsWith('.mp4');
  };

  const isImageFile = (url) => {
    return url && (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif'));
  };

  return (
    <div className="App"> 
      <div>
        <header className="App-header">
          

          {/* <div className="Greetings">{getValueByName(webpageData, 'App.js_Greetings') || "오늘의 뉴스"}</div> */}
          <div className="radar-text">
            {sloganText || "Loading..."} {/* sloganText 출력 또는 로딩 메시지 */}
          </div>
          <div className="radar">
            <div
              className="radar-animation"
              onAnimationEnd={handleAnimationEnd} // 애니메이션 종료 시 호출
            ></div>
            <div className="beam"></div>
          </div>

          {/* 슬라이딩 메뉴: 애니메이션이 끝난 후에만 표시 */}
          {animationFinished && !showDetails && (
            <div className="SlidingMenu">
              <div
                className="MenuContainer"
                onClick={() => handleItemClick(menuItems[currentIndex])}
                // onWheel={(e) => handleSwipe(e.deltaY > 0 ? 'down' : 'up')}
                onMouseEnter={() => setIsHovered(true)} // 마우스 오버 상태로 설정
                onMouseLeave={() => setIsHovered(false)} // 마우스가 나가면 상태 해제
                onMouseDown={() => setIsHovered(true)} // 마우스 누른 상태 유지
                onMouseUp={() => setIsHovered(false)} // 마우스를 놓으면 상태 해제
                onTouchStart={() => setIsTouching(true)} // 터치 상태로 설정
                onTouchEnd={() => setIsTouching(false)} // 터치가 끝나면 상태 해제
              > 

                {menuItems.length > 0 && currentIndex >= 0 && (
                  <div className="MenuItem" style={{
                    height: isMobileDevice  ? '30vh' : '60vh', // 모바일이 아닌 경우 1.5배 높이 설정
                    position: 'relative', overflow: 'hidden' }}>
                    {menuItems[currentIndex].thumbnailurl && (
                      isVideoFile(menuItems[currentIndex].thumbnailurl) ? (
                        // 비디오 파일일 경우
                        <video
                          autoPlay
                          loop
                          muted
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            zIndex: -1,
                          }}
                        >
                          <source
                            src={`${process.env.PUBLIC_URL + menuItems[currentIndex].thumbnailurl}`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : isImageFile(menuItems[currentIndex].thumbnailurl) ? (
                        // 이미지 파일일 경우
                        <div 
                          // className={`MenuItem ${isAnimating ? 'background-animation' : ''}`} // Add animation class dynamically
                          className={`MenuItem ${isAnimating ? 'background-animation' : ''}`} // Add animation class dynamically
                          style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + menuItems[currentIndex].thumbnailurl})`,
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: -1,
                          }}
                        ></div>
                      ) : null
                    )}
 
                    {/* 제목 표시 */}
                    <div style={{ position: 'relative', zIndex: 1 }}>
                      {menuItems[currentIndex].title}
                    </div>

                    {/* 요약문 말풍선: 포커스된 경우에만 표시 */}
                    <div className="Tooltip">
                      <ReactMarkdown>{menuItems[currentIndex].abstract}</ReactMarkdown> {/* Markdown 적용 */}
                    </div>
                  </div>

                  
                )}


                

              </div>
            </div>
          )}

          

            <Routes> 
                <Route path="/intro" element={<Intro />} />
            </Routes> 

            <div className="TGabyss">
                <Link to="/intro" style={{ color: 'white', backgroundColor: '#269830', textDecoration: 'none' }}> TG ABYSS Corp. </Link> &copy; All Rights Reserved. &nbsp;  
                {/* {getValueByName(webpageData, 'App.js_ceoText') || "대표"}: &nbsp; */}
                {/* {getValueByName(webpageData, 'App.js_ceoName') || "길계태"}, &nbsp; */}
                {getValueByName(webpageData, 'App.js_ceoEmail') || "gategil1149@gmail.com"} 
                {/* | &nbsp;  */}
                {/* {getValueByName(webpageData, 'App.js_phoneText') || "대표번호"}: &nbsp; */}
                {/* {getValueByName(webpageData, 'App.js_phoneNumber') || "010-7235-5108"} &nbsp;  */}
                {/* {getValueByName(webpageData, 'App.js_companyAddressText') || "주소"}: &nbsp; */}
                {/* {getValueByName(webpageData, 'App.js_companyAddress') || "대전시 유성구 관들4길 3 (관평동)"}   */}
                
            </div>  
        </header>
      </div>
    </div>
  );
}

export default Intro;
