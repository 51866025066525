import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Manage.css';

function Manage({ language, user }) {
  const [visitData, setVisitData] = useState([]);
  const [activeTab, setActiveTab] = useState('visitcount');
  // const [selectedTable, setSelectedTable] = useState('words_kr');
  const [selectedCategory, setSelectedCategory] = useState('한국의 유명한 배우');
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('All');
  // const [recordList, setRecordList] = useState([]);
  // const [selectedRecord, setSelectedRecord] = useState(null);
  // const [newRecord, setNewRecord] = useState({ word: '', description: '' });

  const [selectedTable, setSelectedTable] = useState('');
  const [fields, setFields] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [newRecord, setNewRecord] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null); // 선택된 레코드
  const [isEditing, setIsEditing] = useState(false); // 수정 모드 상태
  const [isAdding, setIsAdding] = useState(false); // 추가 모드 상태


  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';

  const userEmail = user?.email || 'unknown';

  const fetchVisitCounts = () => {
    console.log('start fetching visit count');
  
    console.log(`user.email: ${userEmail}`);
    console.log(`SERVER_ROOT: ${SERVER_ROOT}`);
  
    axios
      .get(`${SERVER_ROOT}/api/visitCounts`, {
        params: { userEmail: userEmail },
      })
      .then((response) => {
        // 데이터 정렬: 최근 날짜와 방문 수 기준 내림차순
        const sortedData = response.data.sort((a, b) => {
          const dateA = new Date(a.lastVisitDateTime);
          const dateB = new Date(b.lastVisitDateTime);
  
          if (dateB - dateA !== 0) {
            // 날짜 기준으로 정렬
            return dateB - dateA;
          }
          // 방문 수 기준으로 정렬
          return b.visitCount - a.visitCount;
        });
  
        setVisitData(sortedData);
      })
      .catch((error) => {
        console.error('Error fetching visit counts:', error);
      });
  };


  // 테이블 필드 조회
  const fetchTableFields = (tableName) => {
    axios
      .get(`${SERVER_ROOT}/api/tableFields`, { params: { table: tableName } })
      .then((response) => {
        const filteredFields = response.data.filter(
          (field) => field.Extra !== 'auto_increment' && field.Type !== 'timestamp'
        );

        setFields(filteredFields);

        setNewRecord(
          filteredFields.reduce((acc, field) => {
            if (field.Type.startsWith('enum')) {
              acc[field.Field] = ''; // Enum 필드는 초기값을 빈 문자열로 설정
            } else {
              acc[field.Field] = '';
            }
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        console.error('Error fetching table fields:', error);
      });
  };

  const fetchTableRecords = (tableName) => {
    axios
      .get(`${SERVER_ROOT}/api/tableRecords`, { params: { table: tableName } })
      .then((response) => {
        setRecordList(response.data);
      })
      .catch((error) => {
        console.error('Error fetching table records:', error);
      });
  };

  const handleTableSelect = (tableName) => {
    setSelectedTable(tableName);
    fetchTableFields(tableName);
    fetchTableRecords(tableName);
    setIsEditing(false);
    setIsAdding(false);
  };

  const handleAddRecord = () => {
    axios
      .post(`${SERVER_ROOT}/api/addRecord`, {
        table: selectedTable,
        newRecord,
      })
      .then(() => {
        fetchTableRecords(selectedTable);
        setNewRecord(fields.reduce((acc, field) => ({ ...acc, [field.Field]: '' }), {}));
        setIsAdding(false);
      })
      .catch((error) => {
        console.error('Error adding record:', error);
      });
  };

  const handleDeleteRecord = () => {
    if (!selectedRecord) {
      alert(language === 'KR' ? '레코드를 선택하세요.' : 'Please select a record.');
      return;
    }

    axios
      .delete(`${SERVER_ROOT}/api/deleteRecord`, {
        data: { table: selectedTable, id: selectedRecord.id },
      })
      .then(() => {
        fetchTableRecords(selectedTable);
        setSelectedRecord(null);
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Error deleting record:', error);
      });
  };

  const handleModifyRecord = () => {
    if (!selectedRecord) {
      alert(language === 'KR' ? '레코드를 선택하세요.' : 'Please select a record.');
      return;
    }

    axios
      .put(`${SERVER_ROOT}/api/modifyRecord`, {
        table: selectedTable,
        id: selectedRecord.id,
        updatedRecord: selectedRecord,
      })
      .then(() => {
        fetchTableRecords(selectedTable);
        setSelectedRecord(null);
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Error modifying record:', error);
      });
  };

  const handleEditRecord = () => {
    if (!selectedRecord) {
      alert(language === 'KR' ? '레코드를 선택하세요.' : 'Please select a record.');
      return;
    }

    setIsEditing(true);
    setIsAdding(false); // "새 레코드 추가" 비활성화
  };

  const handleAddButtonClick = () => {
    setIsAdding(true);
    setIsEditing(false); // "새 레코드 수정" 비활성화
  };

  const parseEnumValues = (type) => {
    const match = type.match(/^enum\((.+)\)$/);
    if (match) {
      return match[1].replace(/'/g, '').split(',');
    }
    return [];
  };


  return (
    <div className="Manage">
      <header className="Manage-header">
        <h1>{language === 'KR' ? '관리 페이지' : 'Management Page'}</h1>
      </header>
      <main>
        <div className="ManageContainer">
          <div className="tab-buttons">
            <button
              onClick={() => setActiveTab('visitcount')}
              className={activeTab === 'visitcount' ? 'active' : 'inactive'}
            >
              {language === 'KR' ? '방문자 수 조회' : 'Visit Count'}
            </button>
            <button
              onClick={() => setActiveTab('datamanage')}
              className={activeTab === 'datamanage' ? 'active' : 'inactive'}
            >
              {language === 'KR' ? '데이터 관리' : 'Data Management'}
            </button>
          </div>

          <div className="manage-content">
            {activeTab === 'visitcount' && (
              <div className="visitcount">
                <button className="visit-count-button" onClick={fetchVisitCounts}>
                  {language === 'KR' ? '방문 수 가져오기' : 'Get Visit Count'}
                </button>
                <table className="visit-count-table">
                  <thead>
                    <tr>
                      <th>{language === 'KR' ? '테이블 이름' : 'Table Name'}</th>
                      <th>URL</th>
                      <th>{language === 'KR' ? '방문 수' : 'Visit Count'}</th>
                      <th>{language === 'KR' ? '최근 날짜' : 'Latest Date/Time'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.table_name}</td>
                        <td>{row.url}</td>
                        <td>{row.visitCount}</td>
                        <td>{new Date(row.lastVisitDateTime).toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {activeTab === 'datamanage' && (
              <div className="ManageContainer">
                <div className="table-selection">
                  <select
                    value={selectedTable}
                    onChange={(e) => handleTableSelect(e.target.value)}
                  >
                    <option value="">-- {language === 'KR' ? '테이블 선택' : 'Select Table'} --</option>
                    <option value="Toeicwords_en"> 토익 영단어 </option>
                    <option value="SATwords_en"> SAT 영단어 </option>
                    <option value="GREwords_en"> GRE 영단어 </option>
                    <option value="words_kr"> 국내 배우 이름 (KR)</option>
                    <option value="words_en"> 외국 배우 이름 (EN)</option>
                  </select>
                </div>
      
                {selectedTable && (
                  <>
                    

                    <div className="record-list" >
                      <h3>{language === 'KR' ? '레코드 리스트' : 'Record List'}</h3>
                      <table>
                        <thead>
                          <tr>
                            {fields.map((field) => (
                              <th key={field.Field}>{field.Field}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {recordList.map((record) => (
                            <tr
                              key={record.id}
                              className={selectedRecord?.id === record.id ? 'selected' : ''}
                              onClick={() => setSelectedRecord(record)}
                            >
                              {fields.map((field) => (
                                <td key={field.Field}>{record[field.Field]}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
      
                    <div className="record-control">
                      <button onClick={handleAddButtonClick}>
                        {language === 'KR' ? '추가' : 'Add'}
                      </button>
                      
                      <button onClick={handleEditRecord}>
                        {language === 'KR' ? '수정' : 'Edit'}
                      </button>

                      <button onClick={handleDeleteRecord}>
                        {language === 'KR' ? '삭제' : 'Delete'}
                      </button>
                      
                    </div>
      
                    {isAdding && (
                      <div className="add-record">
                        <h3>{language === 'KR' ? '새 레코드 추가' : 'Add New Record'}</h3>
                        <div className="field-inputs">
                          {fields.map((field) => (
                            <div key={field.Field}>
                              <label>{field.Field}</label>
                              {field.Type.startsWith('enum') ? (
                                <select
                                  value={newRecord[field.Field]}
                                  onChange={(e) =>
                                    setNewRecord({ ...newRecord, [field.Field]: e.target.value })
                                  }
                                >
                                  <option value="">
                                    {language === 'KR' ? '선택하세요' : 'Select'}
                                  </option>
                                  {parseEnumValues(field.Type).map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  type="text"
                                  value={newRecord[field.Field]}
                                  onChange={(e) =>
                                    setNewRecord({ ...newRecord, [field.Field]: e.target.value })
                                  }
                                />
                              )}
                            </div>
                          ))}
                        </div>
                        <button onClick={handleAddRecord}>
                          {language === 'KR' ? '추가' : 'Add'}
                        </button>
                      </div>
                    )}
      
                    {isEditing && (
                      <div className="edit-record">
                        <h3>{language === 'KR' ? '새 레코드 수정' : 'Edit Record'}</h3>
                        <div className="field-inputs">
                          {fields.map((field) => (
                            <div key={field.Field}>
                              <label>{field.Field}</label>
                              {field.Type.startsWith('enum') ? (
                                <select
                                  value={selectedRecord[field.Field]}
                                  onChange={(e) =>
                                    setSelectedRecord({
                                      ...selectedRecord,
                                      [field.Field]: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">
                                    {language === 'KR' ? '선택하세요' : 'Select'}
                                  </option>
                                  {parseEnumValues(field.Type).map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  type="text"
                                  value={selectedRecord[field.Field]}
                                  onChange={(e) =>
                                    setSelectedRecord({
                                      ...selectedRecord,
                                      [field.Field]: e.target.value,
                                    })
                                  }
                                />
                              )}
                            </div>
                          ))}
                        </div>
                        <button onClick={handleModifyRecord}>
                          {language === 'KR' ? '수정' : 'Save'}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div> 
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Manage;
